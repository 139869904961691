<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="card-header text-unset " style="width:100%">
          <h5><b>Active Freedom Program {{ programNumber }}</b></h5>
          <div v-if="timeToPay !== null" style="margin-left: 30px;  height: 150%;">
            <table :style="'border: 1px solid ' + ((timeToPay.weAreLate === true) ? 'red' : 'orange')">
              <tr>
                <td colspan="4"
                  :style="'background-color:' + ((timeToPay.weAreLate === true) ? 'red' : 'orange') + '; color:' + ((timeToPay.weAreLate === true) ? 'white' : 'black') + '; text-align: center; padding-right: 10px; padding-left: 10px;'">

                  {{ ((timeToPay.weAreLate === true) ? 'ALLOWED DELAY PERIOD' : 'TIME LEFT') }}


                </td>
              </tr>
              <tr>
                <td style="text-align: center; padding-right: 10px; padding-left: 10px;">{{ timeToPayCopy.time.day }}</td>
                <td style="text-align: center; padding-right: 10px; padding-left: 10px;">{{ timeToPayCopy.time.hour }}
                </td>
                <td style="text-align: center; padding-right: 10px; padding-left: 10px;">{{ timeToPayCopy.time.minute }}
                </td>
                <td style="text-align: center; padding-right: 10px; padding-left: 10px;">{{ timeToPayCopy.time.second }}
                </td>
              </tr>
              <tr>
                <td style="text-align: center; padding-right: 10px; padding-left: 10px;">DAYS</td>
                <td style="text-align: center; padding-right: 10px; padding-left: 10px;">HOURS</td>
                <td style="text-align: center; padding-right: 10px; padding-left: 10px;">MINUTES</td>
                <td style="text-align: center; padding-right: 10px; padding-left: 10px;">SECONDS</td>
              </tr>
            </table>


          </div>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table class="mb-3" dense :headers="headers" :items="[freedomPrograms]" item-key="id"
        hide-default-footer="true" disable-pagination="true">

        <template v-slot:items="props">

          <td class="">
            {{ props.item.id | formatID }}
          </td>
          <td class="date-time-width">
            {{ props.item.deposits[props.item.deposits.length - 1].id | formatDate }}
          </td>
          <td class="date-time-width">
            {{ props.item.nextPaymentStart | formatDate }}
          </td>
          <td class="">
            <div class="widget-content p-0">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left pr-2">
                    <div :class="'widget-numbers fsize-1 text-' + ((freedomPercents > 100) ? 'warning' : 'primary')">

                      {{ (freedomPercents > 100) ? 100 : freedomPercents }}%
                    </div>
                  </div>
                  <div class="widget-content-right w-100">
                    <div class="progress-bar-xs progress">
                      <div class="progress-bar" :class="'bg-' +

                        ((freedomPercents > 100) ? 'warning' : 'primary')" role="progressbar"
                        :aria-valuenow="freedomPercents" aria-valuemin="0" aria-valuemax="100"
                        :style="'width: ' + getPercentage(props.item.nextPaymentStart) + '%;'">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td class="">
            <button class="mb-2 mr-2 opacity-10 btn badge-btn" :class="'btn-primary'" disabled>
              {{ packToFriendlyName(props.item.programName) }}
            </button>
          </td>
          <td>
            {{ props.item.deposits[0].valueInTokens | formatHtl }} HTL
          </td>
          <td>
            {{ props.item.deposits[0].valueInFiat | formatEur }} EUR
          </td>
          <td>
            {{ props.item.totalValueInTokens | formatHtl }} HTL
          </td>
          <td>
            {{ props.item.totalValueInFiat | formatEur }} EUR
          </td>

          <td>
            <button v-if="(freedomPercents < 100)" style="background-color: grey; color:lightgrey;" disabled>
              NEXT RATE
            </button>
            <span v-else-if="props.item.lockedRate !== undefined && props.item.lockedRate !== null">Waiting for
              deposit</span>

            <v-btn color="primary" text elevation="24" block small @click="dialog = true" v-else
              :class="['btn-pack-primarytext-unset']">
              <template>
                NEXT RATE
              </template>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card elevation="20">
        <v-card-title class="headline grey lighten-2" primary-title>Make the next deposit</v-card-title>
        <v-card-title class="headline">
          <template>
            How much do you want to deposit?
          </template>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-unset" small light flat @click="dialog = false">{{ $t('close') }}</v-btn>
          <v-btn v-if="freedomPrograms.programValueInFiat <= 500000" style="height: 45px;" color="primary"
            class="text-unset" small dark @click="checkFunds(500000, (5000000000 / htlEur))">
            {{ (50 / htlEur).toFixed(2) }} HTL<br>
            (50 EUR)
          </v-btn>
          <v-btn v-if="freedomPrograms.programValueInFiat <= 1000000" style="height: 45px;" color="primary"
            class="text-unset" small dark @click="checkFunds(1000000, (10000000000 / htlEur))">
            {{ (100 / htlEur).toFixed(2) }} HTL<br>
            (100 EUR)
          </v-btn>
          <v-btn v-if="freedomPrograms.programValueInFiat <= 2000000" style="height: 45px;" color="primary"
            class="text-unset" small dark @click="checkFunds(2000000, (20000000000 / htlEur))">
            {{ (200 / htlEur).toFixed(2) }} HTL<br>
            (200 EUR)
          </v-btn>


        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2Confirmation" max-width="500px">
      <v-card elevation="20">
        <v-card-title class="headline grey lighten-2" primary-title>Confirm </v-card-title>
        <v-card-title class="headline">
          <template>
            Confirm this new deposit?
          </template>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-unset" small light flat @click="dialog2Confirmation = false">No</v-btn>
          <v-btn color="primary" class="text-unset" small dark @click="buy">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2SecurePrice" max-width="500px">
      <v-card elevation="20">
        <v-card-title class="headline grey lighten-2" primary-title>Insufficient funds </v-card-title>
        <v-card-title class="headline">
          <template>
            <buy-lock :programType="programType" :programDeposit="programDeposit" :available="available"
              :price="selectedFiatValue / 1e4" :color="'primary'" :pack="pack" :deposit-address="depositAddress"
              :parent="+new Date(freedomPrograms.id)" />
          </template>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-unset" small light flat @click="dialog2SecurePrice = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>
  
<script>
import moment from "moment";
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import BuyLock from "./BuyLock";
export default {
  name: "MyFreedomProgramDetails",
  components: {
    BuyLock
  },
  data() {
    return {
      pack: {
        price: this.$props.freedomPrograms.programValueInFiat / 1e4,
        name: this.$props.freedomPrograms.programFriendlyName,
        id: this.$props.freedomPrograms.programName
      },
      programType: 'freedom',
      headers: [
        { value: 'id', text: "ID", sortable: false },
        { value: 'id', text: "Payment date", sortable: false },
        { value: "nextPaymentStart", text: "Next Payment date", sortable: true },
        {
          value: "percentage",
          text: "Till next payment",
          sortable: false,
        },
        {
          value: "programName",
          text: "Freedom Program",
          sortable: false,
        },
        {
          value: "valueInTokens",
          text: "Value in HTL",
          sortable: true,
        },
        {
          value: "valueInFiat",
          text: "Value in EUR",
          sortable: true,
        },

        {
          value: "totalValueInTokens",
          text: "Total Value in HTL",
          sortable: true,
        },

        {
          value: "totalValueInFiat",
          text: "Total Value in EUR",
          sortable: true,
        },
      ],
      dialog: false,
      dialog2Confirmation: false,
      dialog2SecurePrice: false,
      timeToPayCopy: null,
      selectedFiatValue: 0,
      selectedHTLValue: 0,
      notEnought: false

    };
  },
  props: [
    "nextPaymentEnd",
    "programNumber",
    'timeToPay',
    'freedomPrograms',
    'programDeposit',
    'lockedRate',
    'depositAddress',
    'freedomPercents',
    'available',
    'tokenx',
    'htlEur'

    //     "lockedRate"
  ],
  mounted() {
    this.timeToPayCopy = this.timeToPay;
    this.calculateNewTime();
  },



  computed: {
    ...mapGetters({
      htlEur: 'prices/htlEur',
      token: 'prices/rate'
    })
  },
  methods: {

    calculateNewTime() {

      if (!this.timeToPayCopy) { return }
      let self = this;

      var delta = this.timeToPayCopy.time.delta - 1;
      var deltax = delta;

      var days = Math.floor(deltax / 86400);
      deltax -= days * 86400;

      // calculate (and subtract) whole hours
      var hours = Math.floor(deltax / 3600) % 24;
      deltax -= hours * 3600;

      // calculate (and subtract) whole minutes
      var minutes = Math.floor(deltax / 60) % 60;
      deltax -= minutes * 60;

      // what's left is seconds
      var seconds = Math.round(deltax % 60);
      this.timeToPayCopy = { weAreLate: this.timeToPay.weAreLate, time: { day: days, hour: hours, minute: minutes, second: seconds, delta: delta } };


      if (delta != 0) {
        setTimeout(function () {
          self.calculateNewTime()
        }, 1000);
      }

    },

    checkFunds(fiat, htlTokens) {

      this.selectedFiatValue = fiat;
      //console.log(fiat, htlTokens, this.available);

      if (this.available < htlTokens) {
        this.notEnought = true;
        this.dialog2SecurePrice = true;
        this.dialog = false;
      }
      else {
        this.dialog2Confirmation = true;
        this.notEnought = false;
      }
    },
    buy() {
      console.log(this.$props.packId, this.$props.value);
      this.dialog2Confirmation = false;
      this.dialog = false;

      this.$apollo.mutate({
        // Query
        mutation: gql`mutation($parent:DateTime!, $programName:String!,$fiat:Float!,$token:String!){makeNewDepositInFreedomProgram(parent:$parent,programName:$programName, fiat:$fiat, token:$token)}`,
        // Parameters
        variables: {
          parent: this.$props.freedomPrograms.id,
          programName: this.$props.freedomPrograms.programName,
          fiat: this.selectedFiatValue,
          token: this.$props.tokenx

        },
      }).then(() => {
        this.$router.go(0);
      })


    },

    getPercentage(nextPaymentDate) {
      return Math.round(

        (30 //št. dni kakor je dolgo obdobje
          - ((new Date(nextPaymentDate) - new Date())
            /
            (1000 * 60 * 60 * 24))) * 3.333 //3.333 je en dan v procentih ali izracunano 1/30
      );
    },

    getTimeDiff(dateStart, dateEmd) {
      var delta = Math.abs(dateEmd - dateStart) / 1000;

      // calculate (and subtract) whole days
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      var seconds = Math.round(delta % 60);

      return { day: days, hour: hours, minute: minutes, second: seconds }

    },
    packToFriendlyName(e) {
      switch (e) {
        case '50-fp1':
        case '50-fp2':
          return 'Basic';
        case '100-fp1':
        case '100-fp2':
          return 'Advanced';
        case '200-fp1':
        case '200-fp2':
          return 'Premium';
      }
      return 'Basic';
    },



    transformDataActive(p) {
      var now = new Date();
      var fullDiff = moment(p.expiresAt).diff(moment(p.id));
      var diff = moment(now).diff(moment(p.id));
      return {
        diff: Math.round((100 * diff) / fullDiff),
        color:
          p.valueInFiat[0] < 19000000
            ? "primary"
            : p.valueInFiat[0] < 900000000
              ? "success"
              : "dark",
        button: true,
        allowedUpgrades: p.allowedUpgrades,
        ...p,
      };
    },
    transformDataSwap(p) {
      var result = {};
      var now = new Date();
      {
        let fullDiff = moment(p.dateSellEligible).diff(moment(p.id));
        let diff = moment(now).diff(moment(p.id));
        let percentFilled = Math.min(Math.round((100 * diff) / fullDiff), 100);

        Object.assign(p, {
          diff: percentFilled,
          color: "danger",
          buttonSell: diff >= fullDiff,
          buttonSwap: true,
          // allowedUpgradesSwap: p.allowedUpgrades
        });
      }

      return p;
    },
    transformDataExpired(p) {
      return {
        color:
          p.valueInFiat[0] < 19000000
            ? "primary"
            : p.valueInFiat[0] < 900000000
              ? "success"
              : "dark",
        ...p,
      };
    },
  },
};
</script>
  
<style scoped></style>